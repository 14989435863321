<section class="iris-modal-container form-container discharge-patient">
  <div class="form-title">Discharge Patient</div>

  <div class="form-body">
    <form
      class="iris-form discharge-form"
      autocomplete="off"
      *ngIf="canDischarge()"
      [formGroup]="rDischargeForm"
      (ngSubmit)="handleDischargePatient(rDischargeForm.value)"
    >
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" [hideRequiredMarker]="true">
              <mat-label>Discharge disposition</mat-label>
              <mat-select
                placeholder="Select role"
                class="iris-input"
                formControlName="patICUDischargeDisposition"
              >
                <mat-option
                  *ngFor="let dispostion of dischargeDisposition"
                  [value]="dispostion"
                  >{{ dispostion }}</mat-option
                >
              </mat-select>
              <mat-error>Mandatory Field</mat-error>
            </mat-form-field>
          </div>
        </div>
        <!-- <div class="row-right"></div> -->
      </div>

      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <label for="fallHistory">Palliative* </label> <br />
            <mat-button-toggle-group formControlName="palliative">
              <mat-button-toggle [value]="'yes'">Yes</mat-button-toggle>
              <mat-button-toggle [value]="'no'">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row-right"></div>
      </div>

      <div class="date-time-row">
        <div class="warning-holder">
          <span class="iris-error" *ngIf="serverError">
            {{ serverError }}
          </span>

          <span class="iris-error" *ngIf="dischargeTimeError">{{
            dischargeTimeError
          }}</span>
        </div>

        <div class="content">
          <app-date-time-picker
            [defaultValue]="todayDate"
            [showTime]="true"
            [minValidDate]="minDate"
            [maxValidDate]="maxDate"
            [showNowButton]="true"
            [enableValidation]="true"
            formControlName="timestamp"
          ></app-date-time-picker>

          <div class="b-holder">
            <button
              mat-dialog-close
              mat-flat-button
              class="button-secondary mr"
              type="button"
            >
              Cancel
            </button>
            <button
              [disabled]="!rDischargeForm.valid || disableBtn"
              mat-flat-button
              class="button-primary"
              type="submit"
            >
              Discharge
              <app-loader
                [buttonLoader]="true"
                *ngIf="disableBtn === 'discharge'"
              ></app-loader>
            </button>
          </div>
        </div>
      </div>
    </form>

    <div class="criteria-for-discharge" *ngIf="!canDischarge()">
      <div *ngIf="!isBedAssigned">
        Please assign a bed to this patient prior to discharge.
      </div>
      <div *ngIf="!isAadhar && !isPhone">
        Please fill in the Aadhar Card Number or Phone Number for this patient
        prior to discharge.
      </div>
      <div *ngIf="!isAddress">
        Please fill in Address Line 1, Pincode, City and State for patient prior
        to discharge.
      </div>
      <div *ngIf="!isChronic">
        Please fill in Chronic dx details to this patient prior to discharge.
      </div>

      <mat-dialog-actions align="end">
        <button
          type="button"
          class="button-secondary"
          mat-flat-button
          mat-dialog-close
        >
          Close
        </button>
      </mat-dialog-actions>
    </div>
  </div>

  <div *ngIf="false" class="modal-body small discharge_modal">
    <div
      *ngIf="isFormSubmitted; then patDischargedBlock; else dischargeFormBlock"
    ></div>
    <ng-template #patDischargedBlock>
      <div class="row d-flex justify-content-center">
        {{ _patientService?.displayName(currentPatient, false) }} successfully
        discharged
      </div>
    </ng-template>
    <ng-template #dischargeFormBlock>
      <form
        *ngIf="canDischarge()"
        [formGroup]="rDischargeForm"
        (ngSubmit)="dischargePatient(rDischargeForm.value)"
      >
        <!-- Discharge Disposition & palliative -->
        <div class="row mt-2">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5 mt-1">
                <label for="patICUDischargeDisposition"
                  >Discharge Disposition*</label
                >
              </div>
              <div class="col-md-7">
                <select
                  class="col-md-12 cp-select dischargeDisp"
                  formControlName="patICUDischargeDisposition"
                >
                  <option
                    value="Discharged to home/self care as per medical advice"
                  >
                    Discharged to home/self care as per medical advice
                  </option>
                  <option
                    value="Discharged to home/self care AGAINST medical advice (DAMA)"
                  >
                    Discharged to home/self care AGAINST medical advice (DAMA)
                  </option>
                  <option value="Discharged to ward/floor">
                    Discharged to ward/floor
                  </option>
                  <option
                    value="Discharged to Intermediate Care Unit/High Dependency Unit (HDU)"
                  >
                    Discharged to Intermediate Care Unit/High Dependency Unit
                    (HDU)
                  </option>
                  <option
                    value="Discharged to other ICU within the same hospital"
                  >
                    Discharged to other ICU within the same hospital
                  </option>
                  <option value="Discharged to another facility">
                    Discharged to another facility
                  </option>
                  <option value="Discharged to higher centre of care">
                    Discharged to higher centre of care
                  </option>
                  <option value="Death">Death</option>
                </select>
              </div>
            </div>

            <div
              class="cp-error"
              *ngIf="
                !rDischargeForm.controls['patICUDischargeDisposition'].valid &&
                rDischargeForm.controls['patICUDischargeDisposition'].touched
              "
            >
              Discharge Disposition is required
            </div>
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-6 mt-1">
                <label for="palliative">Palliative*</label>
              </div>
              <div class="col-md-6">
                <div
                  class="btn-group btn-group-toggle pallBtns"
                  ngbRadioGroup
                  formControlName="palliative"
                >
                  <label ngbButtonLabel class="cp-btn cp-outline-misc-p btn-sm">
                    <input ngbButton type="radio" [value]="'yes'" /> Yes
                  </label>
                  <label ngbButtonLabel class="cp-btn cp-outline-misc-p btn-sm">
                    <input ngbButton type="radio" [value]="'no'" /> No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Discharge Date -->
        <div class="row mt-2">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5 mt-1">
                <label for="patICUDischargeDate">Discharge Date*</label>
              </div>
              <div class="col-md-7">
                <div class="input-group">
                  <input
                    class="cp-input cp-outline-info-s dateInput"
                    type="text"
                    size="10"
                    placeholder="yyyy-mm-dd"
                    name="dp"
                    formControlName="patICUDischargeDate"
                    ngbDatepicker
                    #d="ngbDatepicker"
                    [minDate]="minMaxDate.min"
                    [maxDate]="minMaxDate.max"
                  />
                  <div class="input-group-append">
                    <button
                      class="cp-btn cp-hvr-info-s"
                      (click)="d.toggle()"
                      type="button"
                    >
                      <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>
                    </button>
                  </div>
                  <div
                    class="cp-error d-flex align-items-center"
                    *ngIf="
                      !rDischargeForm.controls['patICUDischargeDate'].valid &&
                      rDischargeForm.controls['patICUDischargeDate'].touched
                    "
                  >
                    Discharge Date is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-6 mt-1">
                <label for="patICUDischargeTime">Discharge Time*</label>
              </div>
              <div class="col-md-6">
                <ngb-timepicker
                  class="cp-ngb-input"
                  formControlName="patICUDischargeTime"
                  [spinners]="false"
                >
                </ngb-timepicker>
              </div>
            </div>

            <div
              class="cp-error"
              *ngIf="
                !rDischargeForm.controls['patICUDischargeTime'].valid &&
                rDischargeForm.controls['patICUDischargeTime'].touched
              "
            >
              Hour and Minutes required
            </div>
          </div>
        </div>
        <!-- Submit button -->

        <div class="row d-flex justify-content-center mt-3">
          <!-- <input type="submit" class="cp-btn cp-hvr-info" value="Discharge" [disabled]="!rDischargeForm.valid" /> -->
          <button
            type="submit"
            class="cp-btn cp-hvr-secondary"
            [disabled]="!rDischargeForm.valid || disableBtn"
          >
            <fa-icon
              [icon]="['fas', 'spinner']"
              pulse="true"
              class="mr-1"
              *ngIf="disableBtn === 'discharge'"
            >
            </fa-icon>
            Discharge
          </button>
        </div>
      </form>
      <div *ngIf="!isBedAssigned">
        Please assign a bed to this patient prior to discharge.
      </div>
      <div *ngIf="!isAadhar && !isPhone">
        Please assign either aadhar card number or phone number to this patient
        prior to discharge.
      </div>
      <!-- <div *ngIf="!isPhone">
      Please assign phone number to this patient prior to discharge.
    </div> -->
      <div *ngIf="!isAddress">
        Please assign address details (line1, pincode, city, state) to this
        patient prior to discharge.
      </div>
      <div *ngIf="!isChronic">
        Please assign chronic dx to this patient prior to discharge.
      </div>
    </ng-template>
  </div>
</section>
