import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { throwError, BehaviorSubject, Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { IntakeObj, OutputObj } from "../models/io";
import { environment } from "src/environments/environment";
import { PatientUrlSwitchService } from "./patient-url-switch.service";

@Injectable({
  providedIn: "root",
})
export class IoService {
  env = environment;
  apiUrl: string;

  constructor(
    private _http: HttpClient,
    private _patientUrlSwitchService: PatientUrlSwitchService
  ) {}

  errorHandler(error: HttpErrorResponse) {
    const errormessage: string = error?.error?.message || error.message;
    return throwError(errormessage || "Server error");
  }

  submitIoData(obj: IntakeObj | OutputObj, cpmrn, ioProcess, encounters) {
    let dataToSend = {
      ioData: obj,
      cpmrn: cpmrn,
      encounters: encounters,
      ioProcess: ioProcess,
    };

    return this._http
      .post(this.env.apiUrl + "io/" + "postIo", dataToSend)
      .pipe(catchError(this.errorHandler));
  }

  getIoData(obj) {
    return this._http
      .get(
        this.env.apiUrl + "io/" + "getIo/" + obj.cpmrn + "/" + obj.encounters
      )
      .pipe(catchError(this.errorHandler));
  }

  // Behaviour subject for view update
  private io = new Subject();
  notifyIoUpdate = this.io.asObservable();

  notifyIo(cpmrn) {
    this.io.next(cpmrn);
  }

  // Behaviour subject for input update
  private ioForm = new Subject();
  notifyIoEvent = this.ioForm.asObservable();

  notifyIoClick(obj) {
    this.ioForm.next(obj);
  }

  // Behaviour subject for ioObj update
  private ioProps = new BehaviorSubject("");
  notifyIoProps = this.ioProps.asObservable();

  notifyPropsChange(obj) {
    this.ioProps.next(obj);
  }

  downloadImage({
    CPMRN,
    key,
  }: {
    CPMRN: string;
    key: string;
    bucket?: string;
  }) {
    const api = this._patientUrlSwitchService.getPatientApiUrl(CPMRN);
    return this._http
      .post<any>(api + "io/" + "download", { key: key })
      .pipe(catchError(this.errorHandler));
  }
}
