import { Injectable } from "@angular/core";
import { FormGroup, UntypedFormGroup, ValidationErrors } from "@angular/forms";
import autoTable, { jsPDFDocument, UserOptions } from "jspdf-autotable";
import moment from "moment";
import { font } from "src/app/models/jspdf";
import { robo_bold } from "src/assets/fonts/Roboto-Bold-normal.js";
import { robo_light } from "src/assets/fonts/Roboto-Light-normal.js";
import { robo_medium } from "src/assets/fonts/Roboto-Medium-normal.js";
import { robo_regularNormal } from "src/assets/fonts/Roboto-Regular-normal.js";
import { marginPadding } from "../models/jspdf/marginPadding.model";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  constructor() {}

  private readonly jsPDFFontFamily: string = "Roboto";

  /*Create a different entry for each font weight because jsPDF-Autotable does not
   * support using different font weight from single entry font list.
   * Roboto: ["light","normal", "medium", "bold"]  ❌
   * Roboto-light, Roboto-normal, Roboto-medium, Roboto-bold ✅
   */

  public readonly fontWeightMapping = {
    300: `${this.jsPDFFontFamily}-light`,
    400: `${this.jsPDFFontFamily}-normal`,
    500: `${this.jsPDFFontFamily}-medium`,
    700: `${this.jsPDFFontFamily}-bold`,
  };

  hasError(group: any, controlName: string, errorName: string): boolean {
    if (!!controlName) return group.controls[controlName].hasError(errorName);
    // for nested controlller inside group
    else {
      return group.errors && group.errors[errorName]; // for group errors
    }
  }

  endTimeValidator = (timeZone) => {
    return (group): ValidationErrors | null => {
      const getDateTime = (dateTimeGroup: UntypedFormGroup): number => {
        if (!dateTimeGroup?.value) return null;
        const { date, minute, hour } = dateTimeGroup.value,
          dateTimeObj = moment(date);
        return dateTimeObj
          .tz(timeZone)
          .set({ hour: hour, minute: minute ?? 0, second: 0, millisecond: 0 })
          .valueOf();
      };
      const startDateTime = getDateTime(group.get("startTime"));
      const endDateTime = getDateTime(group.get("endTime"));
      if (endDateTime < startDateTime) {
        return { endDateLesser: true };
      }

      return null;
    };
  };

  // Validation to make sure that end date & time is always 5 mins ahead of start date & time
  getDataUri(url): Promise<string | Event> {
    let image = new Image();
    image.src = `${url}`;
    image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain

    return new Promise((res, rej) => {
      image.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0);
        try {
          res(canvas.toDataURL("image/jpeg"));
        } catch (e) {
          rej(e);
        }
      };
      image.onerror = (e) => {
        rej(e);
      };
    });
  }

  setImageHeader(
    doc: jsPDFDocument,
    imgData,
    containerWidth = 50,
    containerHeight = 18,
    xPos = 15,
    yPos = 9.6
  ) {
    if (!imgData) return;
    const imgProps = doc.getImageProperties(imgData);
    const imgAspectRatio = imgProps.width / imgProps.height;
    let imgHeight = containerHeight;
    let imgWidth = imgAspectRatio * imgHeight;
    if (imgWidth > containerWidth) {
      imgWidth = containerWidth;
      imgHeight = imgWidth / imgAspectRatio;
      yPos += (containerHeight - imgHeight) / 2;
    }
    doc.addImage(imgData, "JPEG", xPos, yPos, imgWidth, imgHeight);
  }

  // This is to check if the control is invalid and not the parent form.
  public isFormControlInvalid(form: FormGroup): boolean {
    return Object.keys(form.controls).some((key) => form.get(key).invalid);
  }

  isValidHttpUrl(str: string) {
    let url;

    try {
      url = new URL(str);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  getTransformmedLen(
    oldContainerLen: number,
    newContainerLen: number,
    len: number
  ) {
    return (len * newContainerLen) / oldContainerLen;
  }

  /**
   * Sets the typography of font. Use before setting text in PDF.
   * Tranform the font dimension which is defined in figma to PDF container
   * dimension before using this method
   * @param {any} doc - PDF instance
   * @param {number} fontSize - Font size in pixel
   * @param {string} fontFamily - Font family
   * @param {number} lineHeight - Line height in pixel
   * @param {string} fontStyle - Consult JsPDF for fontStyle types
   */
  setFont({ doc, fontSize, lineHeight, fontFamily, fontStyle }: font) {
    doc?.setFont(fontFamily, fontStyle ?? "normal");
    doc?.setFontSize(fontSize);
    doc?.setLineHeightFactor(lineHeight / fontSize);
  }

  /**
   * Get width of text according to typography of text.
   * Tranform the font dimension which is defined in figma to PDF container
   * dimension before using this method.
   * Don't call set font method on PDF before calling this method because this method already do that.
   * @param {string} text - Text for which we have to find width
   * @param {font} font - Typography of font
   * @returns {number} - Width of font in pixel
   */
  getTextWidth(text: string, font: font): number {
    this.setFont(font);
    return font.doc.getTextWidth(text);
  }

  /**
   * Get PDF dimension according to type of page configured during initialization.
   * Unit used during jsPDF initialization will be the unit of dimension
   */
  canvasDimension(doc: any) {
    const { width: PDFWidth, height: PDFHeight } = doc?.internal?.pageSize;
    return { PDFWidth: PDFWidth || 0, PDFHeight: PDFHeight || 0 };
  }

  /**
   * Add table heading in PDF. Conversion of lineHeight, fontSize, top, rowHeight will be taken care by this function,
   * So pass dimension mentioned in figma.
   * @param text - Table header
   * @param lineHeight - Lineheight css property of text in pixel
   * @param fontSize - fontSize css property of text in pixel
   * @param top - Distance from top of page in pixel
   * @param rowHeight - Height of row in pixel
   * @param doc - JsPDF instance
   * @param figmaDimension - Figma dimension in [width, height] form in pixel
   * @param pageMargin - PDF margin in pixel
   * @param fontFamily - Font family of text
   * @param tableCellPadding - Header padding pixel
   * @param customTableData - Used to override or to pass extra options to autoTable method
   */
  addTableHeading(
    text: string,
    lineHeight: number,
    fontSize: number,
    top: number,
    rowHeight: number,
    doc: any,
    figmaDimension: [number, number],
    pageMargin: Partial<marginPadding>,
    fontFamily: string,
    tableCellPadding: marginPadding,
    customTableData?: UserOptions
  ) {
    const { PDFWidth, PDFHeight } = this.canvasDimension(doc);
    const [figmaWidht, figmaHeight] = figmaDimension;
    const { left: marginLeft, right: marginRight } = pageMargin;
    doc.setLineHeightFactor(
      this.getTransformmedLen(figmaHeight, PDFHeight, lineHeight) /
        this.getTransformmedLen(figmaWidht, PDFWidth, fontSize)
    );
    autoTable(doc, {
      startY: this.getTransformmedLen(figmaHeight, PDFHeight, top),
      margin: {
        top: 0,
        left: marginLeft,
        right: marginRight,
        bottom: 0,
      },
      head: [[text]],
      headStyles: {
        fillColor: "#E2E8F4",
        textColor: "#2B2A3A",
        font: fontFamily,
        fontStyle: "normal",
        fontSize: this.getTransformmedLen(figmaWidht, PDFWidth, fontSize),
        valign: "middle",
        halign: "left",
        cellPadding: tableCellPadding,
        //TODO: Add font weight
      },
      ...(customTableData || {}),
      willDrawCell: ({ row }) => {
        row.height = this.getTransformmedLen(figmaHeight, PDFHeight, rowHeight);
      },
    });
  }

  /**
   * Add table body. Conversion of lineHeight, fontSize, top, rowHeight will be taken care by this function,
   * So pass dimension mentioned in figma.
   * @param top - Distance from top of page in pixel
   * @param lineHeight - Lineheight css property of text in pixel
   * @param fontSize - fontSize css property of text in pixel
   * @param rowHeight - Height of row in pixel. Pass -1 if row height should be adjusted based on the text content
   * @param doc - JsPDF instance
   * @param figmaDimension - Figma dimension in [width, height] form in pixel
   * @param pageMargin - PDF margin in pixel
   * @param fontFamily - Font family of text
   * @param tableCellPadding - Header padding pixel
   * @param customTableData - Used to override or to pass extra options to autoTable method
   */
  addTableBody(
    top: number,
    lineHeight: number,
    fontSize: number,
    rowHeight: number,
    doc: any,
    figmaDimension: [number, number],
    pageMargin: Partial<marginPadding>,
    fontFamily: string,
    tableCellPadding: marginPadding,
    customTableData?: UserOptions
  ) {
    const { PDFWidth, PDFHeight } = this.canvasDimension(doc);
    const [figmaWidht, figmaHeight] = figmaDimension;
    const { left: marginLeft, right: marginRight } = pageMargin;
    doc.setLineHeightFactor(
      this.getTransformmedLen(figmaHeight, PDFHeight, lineHeight) /
        this.getTransformmedLen(figmaWidht, PDFWidth, fontSize)
    );

    autoTable(doc, {
      startY: this.getTransformmedLen(figmaHeight, PDFHeight, top),
      margin: {
        left: marginLeft,
        right: marginRight,
        bottom: 0,
        top: 0,
      },
      bodyStyles: {
        fillColor: "#ffffff",
        textColor: "#2B2A3A",
        font: fontFamily,
        fontSize: this.getTransformmedLen(figmaWidht, PDFWidth, fontSize),
        fontStyle: "normal",
        valign: "middle",
        halign: "left",
        cellPadding: tableCellPadding,
      },
      willDrawCell: ({ row }) => {
        if (rowHeight != -1) {
          row.height = this.getTransformmedLen(
            figmaHeight,
            PDFHeight,
            rowHeight
          );
        }
      },
      ...(customTableData || {}),
    });
  }

  /**
   * Get height of text according to typography of text.
   * Tranform the font dimension which is defined in figma to PDF container
   * dimension before using this method.
   * Don't call set font method on PDF before calling this method because this method already do that.
   * @param {string} text - Text for which we have to find width
   * @param {font} font - Typography of font
   * @param { number} availableWidth - Available width excluding left and right margin
   * @returns {number} - Height of text in pixel
   */
  getTextHeight(text: string, font: font, availableWidth: number): number {
    this.setFont(font);
    const textWidth = font.doc.getTextWidth(text);
    return Math.ceil(textWidth / availableWidth) * font.lineHeight;
  }

  /**
   * Add font family to PDF
   * TODO Add font weight 900 if required
   */
  addFontFamily(doc: any) {
    doc?.addFileToVFS("Roboto-Light-normal.ttf", robo_light);
    doc?.addFont(
      "Roboto-Light-normal.ttf",
      this.fontWeightMapping[300],
      "normal"
    );

    doc?.addFileToVFS("Roboto-Regular.ttf", robo_regularNormal);
    doc?.addFont("Roboto-Regular.ttf", this.fontWeightMapping[400], "normal");

    doc?.addFileToVFS("Roboto-Medium-normal.ttf", robo_medium);
    doc?.addFont(
      "Roboto-Medium-normal.ttf",
      this.fontWeightMapping[500],
      "normal"
    );

    doc?.addFileToVFS("Roboto-Bold-normal.ttf", robo_bold);
    doc?.addFont(
      "Roboto-Bold-normal.ttf",
      this.fontWeightMapping[700],
      "normal"
    );
  }
}
