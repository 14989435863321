import { createReducer, on, Action } from "@ngrx/store";

import * as ioActions from "src/app/store/actions/patient-chart/io/io.actions";
import * as rootActions from "src/app/store/actions/root.actions";

export interface State {
  ioObj: [];
  intakeProps: {};
  outputProps: {};
  proceduresConnected: {};
  clickedDay: {};
  changedTime: {};
}

const initialState: State = {
  ioObj: [],
  intakeProps: {},
  outputProps: {},
  proceduresConnected: {},
  clickedDay: {},
  changedTime: {},
};

const ioDataReducer = createReducer(
  initialState,
  on(
    ioActions.setIo,
    (state, { ioObj, intakeProps, outputProps, proceduresConnected }) => {
      return {
        ...state,
        ioObj: ioObj,
        intakeProps: intakeProps,
        outputProps: outputProps,
        proceduresConnected: proceduresConnected,
      };
    }
  ),
  on(ioActions.setClickedTime, (state, { day }) => ({
    ...state,
    clickedDay: day,
  })),
  on(ioActions.resetClickedTime, (state) => ({ ...state, clickedDay: {} })),
  on(ioActions.setChangedTime, (state, { dateObj }) => ({
    ...state,
    changedTime: dateObj,
  })),
  on(rootActions.logout, (state) => initialState),
  on(rootActions.patientChartReset, (state) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return ioDataReducer(state, action);
}
