import { Cosign, NoteContent } from "@notes/models/notes.model";
import { Addendum } from "src/app/models/notes-addendum";

export interface Content {
  impactCase: boolean;
  components: Component[];
  icdCodes: any[];
  author: Author;
  registration_id: null;
  authorIsClient: boolean;
  noteType: string;
  noteSubType: string;
  consent: Consent[];
  incompleteConsent: boolean;
  timestamp: Date;
  chargeable: null;
  pendOrSigned: string;
  addendum: Addendum[];
}

export interface Author {
  name: string;
  email: string;
  role: string;
}

export interface Component {
  id: string;
  displayName: string;
  type: string;
  value: string;
}

export interface Consent {
  section: string;
  name: string;
  signature: IDProof;
  type: string;
  idProof?: IDProof;
  photo?: IDProof;
  relationWithPatient?: string;
  contact?: Contact;
}

export interface Contact {
  phoneNumber: string;
  isdCode: string;
}

export interface IDProof {
  key: null | string;
  type: string;
}

export class FinalNotes extends NoteContent {
  isDeleteEnabled?: boolean;
  timestamp?: Date;
  createdTimestamp?: Date;
  lock?: { name: string; uniqueID: string };
  cosign?: Cosign;
  author?: Author;
  authorIsClient?: boolean;
  authorSpeciality?: string;
  registration_id?: string;
  impactCase?: boolean;
  chargeable?: boolean;
  // content?: Content[];
}

export interface Author {
  name: string;
  email: string;
  role: string;
  speciality: string;
}

export class DraftNotes extends NoteContent {
  author: Author;
  authorIsClient: boolean;
  timestamp: Date;
  isAddendum: boolean;
  authorSpeciality?: string;
  authorId: string;
  registration_id: string;
  authorType: string;
  impactCase: boolean;
  chargeable: boolean;
}

export class NotesAPIResponse {
  notes: {
    draftNotes: DraftNotes[];
    finalNotes: FinalNotes[];
  };
}

export class FinalNotesAPIResponse {
  notes: {
    finalNotes: FinalNotes;
  };
}

export class DraftNotesAPIResponse {
  notes: {
    draftNotes: DraftNotes;
  };
}
