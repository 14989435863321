<div class="view-added-image">
  <button
    mat-flat-button
    [ngClass]="buttonClass"
    type="button"
    (click)="onButtonClick()"
  >
    {{ buttonLabel }}
    <mat-icon>{{ icon }}</mat-icon>
  </button>
</div>
