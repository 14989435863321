import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-view-image-button",
  templateUrl: "./view-image-button.component.html",
  styleUrls: ["./view-image-button.component.scss"],
})
export class ViewImageButtonComponent {
  @Input() buttonLabel: string = "View";
  @Input() icon: string = "image";
  @Input() buttonClass: string = "button-secondary";

  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

  onButtonClick(): void {
    this.buttonClick.emit();
  }
}
