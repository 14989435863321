import { createAction, props } from "@ngrx/store";

export const getNotes = createAction(
  "[Patient Notes] get patient notes",
  props<{ CPMRN: string; encounters: number; currUser: string }>()
);

export const setNotes = createAction(
  "[Patient Notes] set patient notes",
  props<{ notes: any }>()
);

export const setHighlight = createAction(
  "[Patient Notes] set patient note highlight",
  props<{ indicator: string; noteType: string }>()
);

export const resetHighlight = createAction(
  "[Patient Notes] reset patient note highlight"
);

export const highlightIncompleteConsent = createAction(
  "[Patient Notes] Highlight incomplete patient",
  props<{ tabType: string }>()
);
